import React, { useEffect, useState, useRef, useContext } from "react";
import { instance } from "utils/axios";
import { Table, Spin, Button, Tag, Modal, message, DatePicker } from "antd";
import QRCode from "qrcode.react";
import { AuthContext } from "contexts";
import QuestionForm from "../QuistionForm";
import { useNavigate } from "react-router-dom";
import { Html5QrcodeScanner } from "html5-qrcode";
import { CameraOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
const { RangePicker } = DatePicker;
function StudentDay() {
  const navigate = useNavigate();
  const [date1, setDate1] = useState(dayjs().startOf("week"));
  const [date2, setDate2] = useState(dayjs().endOf("week"));
  const { state } = useContext(AuthContext);
  const student = state.studentInfo?.profile;
  const [attendance, setAttendance] = useState([]);
  const [profile, setProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [quiz, setQuiz] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const qrCodeScannerRef = useRef(null);
  const [scanData, setScanData] = useState(null);
  const scanDataRef = useRef(null);

  useEffect(() => {
    fetchData();
  }, [date1, date2]);
  const fetchQuizDetails = () => {
    instance({
      method: "get",
      url: `/student/daily/quiz`,
    })
      .then((res) => {
        if (res.data) {
          setQuiz(res.data);
          setModalVisible(true);
        } else {
          Modal.info({
            title: "Анхааруулга",
            content: "Өнөөдрийн асуулгаа бөглөсөн байна.",
          });
          navigate("/feed");
        }
      })
      .catch((error) => {
        console.error("Failed to fetch quiz details", error);
      });
  };

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/attendance?date1=${
        date1 ? dayjs(date1).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
      }&date2=${
        date2 ? dayjs(date2).format("YYYY-MM-DD") : dayjs().format("YYYY-MM-DD")
      }`,
    })
      .then((res) => {
        setAttendance(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .then(() => setLoading(false));
    instance({
      method: "get",
      url: `/student/profile`,
    })
      .then((res) => {
        setProfile(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .then(() => setLoading(false));
  };

  const getStudentInfo = () => {
    if (!student) return {};
    const lastNameInitial = student.lastName
      ? student.lastName.charAt(0) + "."
      : "";
    const firstName = student.firstName || "";
    const code = student.code || "Unknown";
    return { lastNameInitial, firstName, code };
  };
  const { lastNameInitial, firstName, code } = getStudentInfo();

  const handleQuestionSubmit = (values) => {
    setLoading(true);
    instance({
      method: "post",
      url: "student/daily/quiz",
      data: values,
    })
      .then((res) => {
        navigate("/feed");
      })
      .catch((err) => {})
      .finally(() => {
        setLoading(false);
      });
  };

  const columns = [
    { title: "Огноо", dataIndex: "attendanceTime", key: "date" },
    {
      title: "Төлөв",
      dataIndex: "status",
      key: "status",
      render: (status) => {
        let color;
        switch (status) {
          case "Ирсэн":
            color = "green";
            break;
          case "Хоцорсон":
            color = "warning";
            break;
          case "Чөлөөтэй":
            color = "blue";
            break;
          case "Тасалсан":
            color = "red";
            break;
          default:
            color = "default";
        }
        return <Tag color={color}>{status}</Tag>;
      },
    },
    // {
    //   title: "Асуулга",
    //   dataIndex: "dailyQuiz",
    //   key: "dailyQuiz",
    //   render: (value) => {
    //     return <Tag color={value ? "success" : "success"}>{value}</Tag>;
    //   },
    // },
  ];

  const handleScanSuccess = (decodedText) => {
    console.log(
      "Decoded text:",
      decodedText,
      "Previous scan data: ",
      scanDataRef.current
    ); 

    if (decodedText !== scanDataRef.current) {
      console.log("New QR code detected:", decodedText);

      scanDataRef.current = decodedText; // This keeps the reference up-to-date

      createAttendance(scanDataRef.current);
    } else {
      console.log("Duplicate or null QR code scanned");
    }
  };
  const handleScanError = (error) => {
    console.debug("QR Code scanning error:", error); // Log error but don't display it
  };

  const createAttendance = (id) => {
    const attendanceData = {
      scheduleId: id,
      type: "Qrcode",
    };

    instance({
      method: "post",
      url: "/student/schedule/attendance",
      data: attendanceData,
    })
      .then((res) => {
        message.success(`Ирц амжилттай тэмдэглэгдлээ: ${scanDataRef.current}`);
        console.log("Attendance Created:", res.data);
      })
      .catch((err) => {
        message.error("Ирц тэмдэглэх үед алдаа гарлаа!");
        console.error("Error creating attendance:", err);
      });
  };

  const initializeQrScanner = () => {
    if (!qrCodeScannerRef.current) {
      qrCodeScannerRef.current = new Html5QrcodeScanner("reader", {
        fps: 10,
        qrbox: 250,
      });
      qrCodeScannerRef.current.render(handleScanSuccess, handleScanError);
    }
  };

  const openQrScanner = () => {
    setIsModalVisible(true);
    setTimeout(() => initializeQrScanner(), 500); // Delay to ensure the modal is rendered
  };

  const closeQrScanner = () => {
    setIsModalVisible(false);
    if (qrCodeScannerRef.current) {
      qrCodeScannerRef.current.clear(); // Clear the QR scanner on modal close
      qrCodeScannerRef.current = null;
      scanDataRef.current = null;
    }
  };

  return (
    <div className="relative flex flex-col items-center h-screen student-home-container">
      {loading ? (
        <Spin size="large" />
      ) : (
        <div className="flex justify-center w-full mt-4">
          <div className="m-8 w-full max-w-[600px]">
            <div className="p-4 text-center bg-white rounded-lg shadow-lg student-info">
              <div className="flex items-center justify-center space-x-4">
                <div className="p-2 bg-primary">
                  <img
                    src={profile?.house?.googleLink}
                    alt="Profile"
                    className="object-cover w-8 h-8 rounded-full"
                  />
                </div>
                <div>
                  <h3 className="inline-block text-2xl font-bold">
                    {lastNameInitial} {firstName}
                  </h3>
                  <p className="text-lg font-semibold">id: {code}</p>
                </div>
              </div>
              <div>
                <p className="">
                  House point:{" "}
                  {Intl.NumberFormat().format(profile?.house?.point || 0)}
                </p>
                House place: {profile?.house?.place}
              </div>
              <div className="flex justify-center my-4">
                <QRCode
                  value={student?.code?.toString() || "25304"}
                  size={150}
                  level={"H"}
                />
              </div>
            </div>

            <div className="flex justify-center gap-2 my-4">
              <Button onClick={fetchData}>Refresh</Button>
              <Button
                type="primary"
                icon={<CameraOutlined />}
                onClick={openQrScanner}
              >
                QR Scanner
              </Button>
            </div>

            <div className="p-4 mt-4 bg-white rounded-lg shadow-lg attendance-list">
              <h3 className="mb-4 text-xl font-semibold">Ирц</h3>

              <RangePicker
                value={[date1, date2]}
                picker="date"
                onChange={(dates) => {
                  if (dates) {
                    setDate1(dates[0]);
                    setDate2(dates[1]);
                  } else {
                    setDate1(dayjs());
                    setDate2(dayjs());
                  }
                }}
              />

              <Table
                dataSource={attendance}
                columns={columns}
                pagination={false}
                className="w-full"
                rowKey={(record) => record.id || record.attendanceTime}
              />
            </div>
          </div>
        </div>
      )}

      <Modal
        title="QR код уншигч"
        open={isModalVisible}
        onCancel={closeQrScanner}
        footer={null}
      >
        <div id="reader" style={{ width: "100%" }}></div>

        {scanData && (
          <div style={{ marginTop: "20px", textAlign: "center" }}>
            <p>
              Сүүлийн уншсан код: <strong>{scanData}</strong>
            </p>
          </div>
        )}
      </Modal>
      {/* Modal for the Question Form */}
      <QuestionForm
        visible={modalVisible}
        quiz={quiz}
        loading={loading}
        onClose={() => setModalVisible(false)}
        onSubmit={handleQuestionSubmit}
      />
      {/* {contextHolder} */}
    </div>
  );
}

export default StudentDay;
