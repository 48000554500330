import React, { useState, useEffect } from "react";
import { Card, Avatar, List, Tooltip, Button } from "antd";
import {
  LikeOutlined,
  LikeFilled,
  CommentOutlined,
  ShareAltOutlined,
} from "@ant-design/icons";
import { instance } from "utils/axios";
const { Meta } = Card;

function Feed() {
  const [liked, setLiked] = useState(false);
  const [likes, setLikes] = useState(0);
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = () => {
    setLoading(true);
    instance({
      method: "get",
      url: `/student/daily/feed`,
    })
      .then((res) => {
        setPosts(res.data);
      })
      .catch((err) => {
        console.log("here");
      })
      .finally(() => setLoading(false));
  };

  const handleLike = () => {
    setLiked(!liked);
    setLikes(liked ? likes - 1 : likes + 1);
  };

  return (
    <div className=" md:ml-20 md:mr-20">
      {posts.map((post, index) => (
        <div className="mb-2">
          <Card
            key={index}
            hoverable
            actions={[
              <Tooltip title="Like">
                <Button
                  type="text"
                  icon={liked ? <LikeFilled /> : <LikeOutlined />}
                  onClick={handleLike}
                >
                  {likes}
                </Button>
              </Tooltip>,
              <Tooltip title="Comment">
                <Button type="text" icon={<CommentOutlined />}>
                  Comment
                </Button>
              </Tooltip>,
            ]}
          >
            <Meta
              avatar={<Avatar src={post.imageUrl} />}
              title={`${post.lastName[0]}. ${post.firstName} `}
              description={
                <div style={{ display: "flex", alignItems: "center" }}>
                  Анги: {post.grade} |
                  <img
                    className="w-5 h-5 p-1 ml-2 mr-1 rounded-full bg-primary"
                    src={post.house.googleLink}
                    alt={post.house.name}
                  />
                  {/* {post.house.name} */}
                </div>
              }
            />
            <div>
              {post.user_quizzes.map((quiz, quizIndex) => (
                <List
                  key={quizIndex}
                  itemLayout="vertical"
                  dataSource={quiz.user_answers}
                  renderItem={(answer) => (
                    <List.Item>
                      <List.Item.Meta
                        title={
                          <span className="text-gray-500">
                            {answer.question.question}
                          </span>
                        }
                        description={
                          <span className="text-black">
                            {answer.answer !== null
                              ? answer.answer
                              : answer.numberValue !== null
                              ? answer.numberValue
                              : "---"}
                          </span>
                        }
                      />
                    </List.Item>
                  )}
                />
              ))}
            </div>
          </Card>
        </div>
      ))}
    </div>
  );
}

export default Feed;
