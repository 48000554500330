import { Link } from "react-router-dom";
import {
  Decision,
  Home,
  Page404,
  Test,
  Feed,
  Lesson,
  Enroll,
  Qpay,
  Payment,
  SeatConfirm,
  StudentPayment,
  StudentQpay,
  Contract,
  PaymentStatus,
} from "pages";
import Layout from "layout";
import Schedule from "pages/Schedule";
import ScheduleAttendance from "pages/ScheduleAttendance";
import Assignment from "pages/Assignment";

const routes = [
  {
    path: "/",
    errorElement: <div>error</div>,
    element: <Layout />,
    children: [
      {
        name: "Home",
        index: true,
        element: <Home />,
      },
      {
        name: "Student qpay",
        path: "/student/qpay/:guid",
        element: <StudentQpay />,
      },
      {
        name: "Not Found",
        path: "/*",
        element: <Page404 />,
      },
      {
        name: "Feed",
        path: "/feed",
        element: <Feed />,
      },
      {
        name: "Assignment",
        path: "/assignment",
        element: <Assignment />,
      },
      {
        name: "Schedule",
        path: "/schedule",
        element: <Schedule />,
      },
      {
        name: "schedule_attendance",
        path: "/schedule_attendance",
        element: <ScheduleAttendance />,
      },
      {
        name: "Seat Confrim",
        path: "/seatconfirm",
        element: <SeatConfirm />,
      },
      {
        name: "Payment Status",
        path: "/paymentstatus",
        element: <PaymentStatus />,
      },
      {
        name: "Payment",
        path: "/payment",
        element: <Payment />,
      },

      {
        name: "StudentPayment",
        path: "/student/payment/:id",
        element: <StudentPayment />,
      },
      { name: "Qpay", path: "payment/qpay/:guid", element: <Qpay /> },
      {
        name: "Contract",
        path: "/contract/:id",
        element: <Contract />,
      },
    ],
  },

  // pages without main layout
  {
    name: "decision",
    path: "/decision",
    element: <Decision />,
    handle: {
      crumb: () => <Link to={"/decision"}>Home</Link>,
    },
  },
  // it will render when there are no pages on the website
  {
    name: "Not Found",
    path: "/*",
    element: <Page404 />,
  },
];

export default routes;
