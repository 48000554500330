import React, { useContext, useEffect, useState } from "react";
import logo from "assets/images/logo.jpg";
import { FaUser } from "react-icons/fa6";
import { Link, NavLink } from "react-router-dom";
import { Button, Dropdown, Space } from "antd";
import { FiLogOut } from "react-icons/fi";
import { AuthContext } from "contexts";
import { GiHamburgerMenu } from "react-icons/gi";
import BurgerMenu from "./BurgerMenu";

const items = [
  {
    key: "1",
    label: "Гарах",
    icon: <FiLogOut />,
    danger: true,
  },
];
const studentMenu = [
  {
    label: "Нүүр",
    path: "/",
    form: "home01",
  },
  // {
  //   label: "Өнөөдөр",
  //   path: "/feed",
  //   form: "feed01",
  // },
  {
    label: "Assignment",
    path: "/assignment",
    form: "assignment01",
  },
  {
    label: "Хичээлийн хуваар",
    path: "/schedule",
    form: "feed0",
  },
  {
    label: "Хичээлийн ирц",
    path: "/schedule_attendance",
    form: "feed0",
  },
];
const headerMenu = [
  {
    label: "Нүүр",
    path: "/",
    form: "home01",
  },
  {
    label: "Төлбөр",
    path: "/paymentStatus",
    form: "payments01",
  },
  // {
  //   label: "Элсэлт",
  //   path: "/admissions",
  //   form: "admissions01",
  // },
  // {
  //   label: "Төлбөр",
  //   path: "/tuition",
  //   form: "tuition01",
  // },
  // {
  //   label: "Хичээл",
  //   path: "/lesson",
  //   form: "lesson01",
  // },
  // {
  //   label: 'POS',
  //   path: '/pos',
  // },
  // {
  //   label: 'RESOURCE',
  //   path: '/resource',
  // },
];
const applicantMenu = [
  {
    label: "Нүүр",
    path: "/",
    form: "homea01",
  },
];

function Header() {
  const [showBurgerMenu, setShowBurgerMenu] = useState(false);
  const { action } = useContext(AuthContext);
  const { state } = useContext(AuthContext);
  const roleId = state.studentInfo?.profile?.roleId;
  const handleClickUser = (event) => {
    if (event.key === "1") {
      action.logout();
    }
  };
  const handleClickBurgerMenu = (e) => {
    e.preventDefault();
    setShowBurgerMenu((prev) => !prev);
  };
  return (
    <>
      <div
        className={
          "fixed inset-x-0 h-[60px] bg-primary2 top-0 flex justify-between px-7 py-2 z-50 transition-all"
        }
      >
        <NavLink to={"/"}>
          <img src={logo} style={{ height: 40 }} />
        </NavLink>
        <div className="items-center hidden gap-4 text-primary md:flex">
          {(roleId == 5
            ? applicantMenu
            : roleId == 4
            ? studentMenu
            : headerMenu
          ).map((menu, i) => (
            <NavLink
              key={i}
              to={menu.path}
              className={({ isActive }) =>
                `menu-item px-3 transition-all rounded ${
                  isActive ? "bg-primary text-primary2" : ""
                }`
              }
            >
              {menu.label}
            </NavLink>
          ))}
          <Dropdown menu={{ items, onClick: handleClickUser }}>
            <button className="p-2 transition-all border border-transparent rounded-full hover:border-primary">
              <FaUser size={18} />
            </button>
          </Dropdown>
        </div>
        <div className="block md:hidden">
          <button
            className="p-2 transition-all border border-transparent rounded-full text-primary hover:border-primary"
            onClick={handleClickBurgerMenu}
          >
            <GiHamburgerMenu size={24} />
          </button>
        </div>
      </div>
      <BurgerMenu showMenu={showBurgerMenu} onClose={handleClickBurgerMenu}>
        <div className="flex flex-col divide-y">
          {(roleId == 5
            ? applicantMenu
            : roleId == 4
            ? studentMenu
            : headerMenu
          ).map((menu, i) => (
            <NavLink
              key={i}
              to={menu.path}
              onClick={() => setShowBurgerMenu(false)}
              className={({ isActive }) =>
                `menu-item px-7 py-2 transition-all rounded ${
                  isActive ? "bg-primary text-primary2" : ""
                }`
              }
            >
              {menu.label}
            </NavLink>
          ))}
          <button
            className="flex items-center gap-2 py-2 text-red-400 px-7"
            onClick={() => action.logout()}
          >
            <FiLogOut size={18} /> ГАРАХ
          </button>
        </div>
      </BurgerMenu>
    </>
  );
}

export default Header;
