import React, { useEffect, useState } from "react";
import {
  Form as AntForm,
  Modal,
  Table,
  Breadcrumb,
  Affix,
  Input,
  Button,
  DatePicker,
} from "antd";
import { useParams, Link } from "react-router-dom";
import {
  DownOutlined,
  SmileOutlined,
  EyeOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { instance } from "utils/axios";
import moment from "moment";
import dayjs from "dayjs";
import "dayjs/locale/mn";
import weekday from "dayjs/plugin/weekday";
import localeData from "dayjs/plugin/localeData";

dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.locale("mn");

const Assignment = () => {
  const { TextArea } = Input;
  const [assignments, setAssignments] = useState([]);
  const [lessons, setLessons] = useState([]);
  const [month, setMonth] = useState(dayjs());
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [selectedAss, setSelectedAss] = useState(null);
  const [data, setData] = useState({
    link: "",
    description: "",
  });

  useEffect(() => {
    fetchAssignment();
  }, [month]);

  const fetchAssignment = () => {
    instance({
      method: "get",
      url: `/student/assignments?month=${month}`,
    })
      .then((response) => {
        setAssignments(response.data);
        const uniqueLessonIds = [
          ...new Set(response.data.map((item) => item.lesson.id)),
        ];
        setLessons(uniqueLessonIds);
      })
      .catch((error) => console.error(`Failed to fetch `, error));
  };

  const handleCreateAss = () => {
    instance({
      method: "post",
      url: `/student/assignment`,
      data: { ...data, assignmentId: selectedAss?.id },
    })
      .then(() => {
        handleCloseModal();
      })
      .catch((error) => console.error(`Failed to fetch `, error));
  };

  const handleCloseModal = () => {
    setData(null);
    setSelectedAss(null);
    setOpenModal(false);
  };

  const handleOpenModal = (assignment) => {
    setSelectedAss(assignment);
    setOpenModal(true);
  };

  return (
    <div className="max-w-[600px] md:m-auto lex flex-col gap-2 relative">
      {/* <h1 className="pt-5 mb-4 font-bold text-center ">Assignment list</h1> */}

      <div className="flex items-center pt-5">
        <div className="flex items-center gap-2 text-xs">
          <div className={`w-3 h-3 bg-[#5c1090] rounded-full `}></div>
          <p>
            Created <br />
            date
          </p>
        </div>
        <div className="w-full text-center">
          <DatePicker
            picker="month"
            format={"M сар YYYY"}
            suffixIcon={<DownOutlined />}
            style={{
              borderColor: "transparent",
              border: "none",
              boxShadow: "none",
            }}
            className="text-lg font-bold"
            value={month}
            onChange={(date) => {
              if (date) {
                setMonth(date);
              } else {
                setMonth(dayjs());
              }
              setOpen(false);
            }}
            inputReadOnly={true}
          />
        </div>
      </div>

      <div className="flex flex-col gap-10 pt-5">
        {assignments?.map((item, i) => {
          const today = moment().format("DD MM YYYY");

          const dueDate = moment(item?.deadline).format("DD MM YYYY");

          const color =
            today === dueDate
              ? "yellow-500"
              : today > dueDate
              ? "red-500"
              : today < dueDate
              ? "[#5c1090]"
              : "default";
          if (
            month.format("MM YYYY") ===
            moment(item?.createdAt).format("MM YYYY")
          )
            return (
              <div
                className="flex items-center gap-3 "
                key={`assignments ${i}`}
              >
                <div className="flex items-center gap-2">
                  <div className={`w-3 h-3 bg-${color} rounded-full`}></div>
                  <div className="flex flex-col items-center gap-0">
                    <p className="text-md">
                      {moment(item?.createdAt).format("DD")}
                    </p>
                    <p className="text-[10px]">
                      {moment(item?.createdAt).format("ddd").toUpperCase()}
                    </p>
                  </div>
                </div>

                <div
                  style={{ boxShadow: "-4px 4px 10px rgba(0, 0, 0, 0.3)" }}
                  className="relative flex flex-col justify-between w-full gap-2 px-5 py-5 md:px-10 rounded-3xl"
                >
                  <div
                    className={`absolute w-2/5 h-3 bg-${color} rounded-full  top-[-6px]`}
                  ></div>

                  <div className="flex justify-between">
                    <h1 className="text-base font-bold md:text-xl">
                      {item.lesson.name}
                    </h1>
                    {item.point ? (
                      <p className="text-sm text-gray-500">
                        {item.point} point
                      </p>
                    ) : (
                      <SmileOutlined className="text-sm text-gray-500" />
                    )}
                  </div>
                  <p>{item.name}</p>
                  <div className="flex justify-between text-end">
                    <div className="flex items-center gap-3">
                      <div className={`px-2 py-1 bg-${color} rounded-full`}>
                        <ClockCircleOutlined className="text-white" />
                      </div>
                      <div className="flex flex-col gap-0 text-start">
                        <p className="text-[10px] text-gray-400">Due time</p>
                        <p className="text-base">
                          {moment(item.deadline).format("ddd DD")}
                        </p>
                      </div>
                    </div>
                    <button
                      className="flex items-center px-3 py-1 bg-purple-100 rounded-full shadow-md shadow-purple-300 "
                      onClick={() => handleOpenModal(item)}
                    >
                      <EyeOutlined className={`text-lg text-${color}`} />

                      <span className="pl-3">Харах</span>
                    </button>
                  </div>
                </div>
              </div>
            );
        })}
      </div>
      <Modal
        title={"Даалгавар илгээх"}
        open={openModal}
        destroyOnClose
        width={800}
        okText="Хадгалах"
        onOk={handleCreateAss}
        onCancel={handleCloseModal}
      >
        <div className="flex flex-col gap-2">
          <div className="flex justify-between">
            <p className="text-xs text-gray-500">
              Deadline:{" "}
              <span className="text-sm font-bold text-black">
                {moment(selectedAss).format("ddd DD MMM")}
              </span>
            </p>
            <p className="text-xs text-gray-500">
              Point:{" "}
              <span className="text-sm font-bold text-black">
                {selectedAss?.point ? selectedAss.point : <SmileOutlined />}
              </span>
            </p>
          </div>
          <div className="flex justify-between">
            <p className="text-xs text-gray-500">
              Lesson:{" "}
              <span className="text-sm font-bold text-black">
                {selectedAss?.lesson.name}
              </span>
            </p>
            <p className="text-xs text-gray-500">
              Assignment:{" "}
              <span className="text-sm font-bold text-black">
                {" "}
                {selectedAss?.name}
              </span>
            </p>
          </div>

          <p className="text-sm font-bold text-black ">
            <span className="text-xs font-normal text-gray-500">
              Description:
            </span>{" "}
            {selectedAss?.description}
          </p>

          <div>
            <p>Assignment description</p>

            <TextArea
              placeholder="Description..."
              autoSize
              value={data?.description}
              onChange={(e) =>
                setData({ ...data, description: e.target.value })
              }
            />
            <p>Assignment link</p>
            <Input
              type="url"
              placeholder="Enter a link..."
              allowClear
              value={data?.link}
              onChange={(e) => setData({ ...data, link: e.target.value })}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default Assignment;
