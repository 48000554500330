import React, { useEffect, useState, useContext, useRef } from "react";

import { Button, Space, List, Table, Tag } from "antd";
import { EyeOutlined } from "@ant-design/icons";
import { useWindowSize } from "react-use";
// import axios from "axios";
import { instance } from "utils/axios";
import { useNavigate } from "react-router-dom";
import logo from "assets/images/logo.jpg";
import { AuthContext } from "contexts";

function ApplicantHome() {
  
  const { state } = useContext(AuthContext);
  const name = state.studentInfo?.profile?.firstName;
  const { width, height } = useWindowSize();
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const [close, setClose] = useState(false);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    instance({
      method: "get",
      url: `/applicant/status`,
    })
      .then((res) => {
        console.log(res.data[0]);
        if (
          (res.data[0].grade == "11-р анги" ||
            res.data[0].grade == "12-р анги" ||
            res.data[0].grade == "12" ||
            res.data[0].grade == "11") &&
          res.data[0].paidAmount == 0
        )
          setClose(true);
        setData(res.data);
      })
      .catch((err) => {})
      .then(() => setLoading(false));
  }, []);
  const columns = [
    {
      title: "Гарчиг",
      dataIndex: "title",
      key: "title",
      render: (text) => <a>{text}</a>,
    },
    {
      title: "Тайлбар",
      dataIndex: "desc",
      key: "desc",
    },

    {
      title: "Хариу",
      key: "status",
      dataIndex: "status",
      render: (status, record) =>
        record.type == "button" && status == "Accept" ? (
          record.seatConfirm && record.stageId == 5 ? (
            <Tag color="success">БАТАЛГААЖСАН</Tag>
          ) : (
            <Button
              className="justify-center"
              style={{ backgroundColor: "#f2cd30", color: "#5c1090" }}
              onClick={() => {
                window.open(record.link, "_blank");
                // navigate("/decision");
              }}
            >
              {/* <EyeOutlined></EyeOutlined> */}
              {record.typeText}
            </Button>
          )
        ) : (
          <Tag
            color={
              status == "Accept"
                ? "success"
                : status == "Reject"
                ? "error"
                : "warning"
            }
            key={status}
          >
            {status == "Accept"
              ? "ТЭНЦСЭН"
              : status == "Reject"
              ? "ТЭНЦЭЭГҮЙ"
              : status ?? record.defaultText}
          </Tag>
        ),
      width: 120,
      fixed: "right",
    },
    // {
    //   title: "Үйлдэл",
    //   key: "action",
    //   dataIndex: "status",
    //   render: (_, record) =>
    //     record.type == "button" ? (
    //       <Button
    //       type="primary"
    //         className="justify-center"
    //         onClick={() => {
    //           window.open(record.link, "_blank");
    //           // navigate("/decision");
    //         }}
    //       >
    //         {/* <EyeOutlined></EyeOutlined> */}
    //         Харах
    //       </Button>
    //     ) : (
    //       <></>
    //     ),
    //   // width: 100,
    //   // fixed: "right",
    // },
  ];

  return (
    <div className="flex flex-col items-center h-screen relative">
      <div className="mt-16 w-full flex justify-center">
        {close ? (
          <div className="m-8 w-4/5">Элсэлт хаагдсан байна</div>
        ) : (
          <div className="m-8 w-4/5">
            <div className=" gap-4 mx-auto py-20 md:py-0 mb-8">
              <div className="text-[20pt] md:text-[20pt] xl:text-[30pt] font-extrabold text-primary leading-[1.38]">
                Сайн уу {name},
              </div>
              <div>
                Таны Томүжин Алтернатив сургуульд элсэх хүсэлтээ илэрхийлсэн
                элсэлтийн үе шатуудын явц болон үе шат бүрийн хариуг доорх
                хүснэгтэнд нэгтгэв. Цаашид та энэхүү системдээ нэвтэрч элсэлтийн
                үе шатуудынхаа явцыг хараарай.
              </div>
            </div>
            <Table columns={columns} dataSource={data} pagination={false} />
          </div>
        )}
      </div>
    </div>
  );
}

export default ApplicantHome;
